"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickTransactionsOrderMap = exports.buildTimeframeInterval = exports.PickType2 = exports.PickGroupType = void 0;
var PickGroupType;
(function (PickGroupType) {
    PickGroupType[PickGroupType["BettorHistorical"] = 0] = "BettorHistorical";
    PickGroupType[PickGroupType["BettorRecent"] = 1] = "BettorRecent";
    PickGroupType[PickGroupType["BettorActive"] = 2] = "BettorActive";
    PickGroupType[PickGroupType["BettorCounts"] = 3] = "BettorCounts";
    PickGroupType[PickGroupType["BettorForSale"] = 4] = "BettorForSale";
    PickGroupType[PickGroupType["BettorActiveCount"] = 5] = "BettorActiveCount";
    PickGroupType[PickGroupType["Featured"] = 6] = "Featured";
    PickGroupType[PickGroupType["Game"] = 7] = "Game";
    PickGroupType[PickGroupType["GameActive"] = 8] = "GameActive";
    PickGroupType[PickGroupType["GameActiveCount"] = 9] = "GameActiveCount";
    PickGroupType[PickGroupType["GameCounts"] = 10] = "GameCounts";
    PickGroupType[PickGroupType["Team"] = 11] = "Team";
    PickGroupType[PickGroupType["Sport"] = 12] = "Sport";
})(PickGroupType || (exports.PickGroupType = PickGroupType = {}));
var PickType2;
(function (PickType2) {
    PickType2[PickType2["AwayMoneyline"] = 0] = "AwayMoneyline";
    PickType2[PickType2["HomeMoneyline"] = 1] = "HomeMoneyline";
    PickType2[PickType2["AwaySpread"] = 2] = "AwaySpread";
    PickType2[PickType2["HomeSpread"] = 3] = "HomeSpread";
    PickType2[PickType2["Over"] = 4] = "Over";
    PickType2[PickType2["Under"] = 5] = "Under";
})(PickType2 || (exports.PickType2 = PickType2 = {}));
const buildTimeframeInterval = (alias, timeframe, tableSuffix = false) => {
    switch (timeframe) {
        case "today":
            if (tableSuffix) {
                return "today";
            }
            return `${alias} >= (NOW() AT TIME ZONE 'UTC')::date + INTERVAL '10 hours'
    AND ${alias} < ((NOW() AT TIME ZONE 'UTC')::date + INTERVAL '34 hours')`;
        case "last_24_hours":
            if (tableSuffix) {
                return "l24h";
            }
            return `${alias} >= NOW() - INTERVAL '24 hours'`;
        case "last_7_days":
            if (tableSuffix) {
                return "l7d";
            }
            return `${alias} >= NOW() - INTERVAL '7 days'`;
        case "last_30_days":
            if (tableSuffix) {
                return "l30d";
            }
            return `${alias} >= NOW() - INTERVAL '30 days'`;
        case "last_3_months":
            if (tableSuffix) {
                return "l3m";
            }
            return `${alias} >= NOW() - INTERVAL '3 months'`;
        case "last_6_months":
            if (tableSuffix) {
                return "l6m";
            }
            return `${alias} >= NOW() - INTERVAL '6 months'`;
        case "last_12_months":
            if (tableSuffix) {
                return "l365d";
            }
            return `${alias} >= NOW() - INTERVAL '12 months'`;
        case "all_time":
            if (tableSuffix) {
                return "at";
            }
            return `${alias} <= NOW()`;
        default:
            return `${alias} >= NOW() - INTERVAL '7 days'`;
    }
};
exports.buildTimeframeInterval = buildTimeframeInterval;
exports.pickTransactionsOrderMap = {
    created_asc: "created_at ASC",
    created_desc: "created_at DESC",
    game_date_asc: "date_time_utc ASC",
    game_date_desc: "date_time_utc DESC",
    price_asc: "sale_price ASC",
    price_desc: "sale_price DESC",
    sales_asc: "sales ASC",
    sales_desc: "sales DESC",
    earnings_asc: "gross_earnings ASC",
    earnings_desc: "gross_earnings DESC",
};

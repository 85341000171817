"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildPaymentMethods = exports.buildPaymentMethod = exports.ALL_PAYMENT_METHOD_TYPES = exports.PaymentMethodType = exports.MarketplaceActionType = exports.KYCStatus = exports.PaymentRail = void 0;
var PaymentRail;
(function (PaymentRail) {
    PaymentRail[PaymentRail["Stripe"] = 0] = "Stripe";
    PaymentRail[PaymentRail["Paypal"] = 1] = "Paypal";
    PaymentRail[PaymentRail["Coinbase"] = 2] = "Coinbase";
    PaymentRail[PaymentRail["Bitpay"] = 3] = "Bitpay";
})(PaymentRail || (exports.PaymentRail = PaymentRail = {}));
var KYCStatus;
(function (KYCStatus) {
    KYCStatus[KYCStatus["NotNecessary"] = 0] = "NotNecessary";
    KYCStatus[KYCStatus["Verified"] = 1] = "Verified";
    KYCStatus[KYCStatus["Required"] = 2] = "Required";
    KYCStatus[KYCStatus["Pending"] = 3] = "Pending";
    KYCStatus[KYCStatus["Failed"] = 4] = "Failed";
})(KYCStatus || (exports.KYCStatus = KYCStatus = {}));
var MarketplaceActionType;
(function (MarketplaceActionType) {
    MarketplaceActionType[MarketplaceActionType["DepositBalance"] = 0] = "DepositBalance";
    MarketplaceActionType[MarketplaceActionType["WithdrawBalance"] = 1] = "WithdrawBalance";
    MarketplaceActionType[MarketplaceActionType["CreatePaymentIntent"] = 2] = "CreatePaymentIntent";
    MarketplaceActionType[MarketplaceActionType["AttachPaymentMethod"] = 3] = "AttachPaymentMethod";
    MarketplaceActionType[MarketplaceActionType["Checkout"] = 4] = "Checkout";
    MarketplaceActionType[MarketplaceActionType["ConfirmPayment"] = 5] = "ConfirmPayment";
})(MarketplaceActionType || (exports.MarketplaceActionType = MarketplaceActionType = {}));
var PaymentMethodType;
(function (PaymentMethodType) {
    PaymentMethodType["Card"] = "card";
})(PaymentMethodType || (exports.PaymentMethodType = PaymentMethodType = {}));
exports.ALL_PAYMENT_METHOD_TYPES = [
    PaymentMethodType.Card,
];
const buildPaymentMethod = (input) => {
    var _a, _b, _c, _d;
    return {
        id: input.id,
        brand: ((_a = input.card) === null || _a === void 0 ? void 0 : _a.brand) || "Unknown",
        last4: ((_b = input.card) === null || _b === void 0 ? void 0 : _b.last4) || "****",
        exp_month: ((_c = input.card) === null || _c === void 0 ? void 0 : _c.exp_month) || 0,
        exp_year: ((_d = input.card) === null || _d === void 0 ? void 0 : _d.exp_year) || 0,
        type: input.type,
    };
};
exports.buildPaymentMethod = buildPaymentMethod;
const buildPaymentMethods = (input) => {
    return input.map((pm) => (0, exports.buildPaymentMethod)(pm));
};
exports.buildPaymentMethods = buildPaymentMethods;
